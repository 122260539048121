// Here you can add other styles
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1440px;
$breakpoint-laptop-m: 1840px;
.buttonPay {
  margin-top: 29px;
  background: white;
  width: 80%;
  color: #22a18a;
  font-weight: bold;
  border-color: #22a18a;
  transition: 500ms;
  &:hover {
    color: white;
    background: #22a18a;
    border-color: #22a18a;
  }
}
.containerSubscriptionCreditCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  .subscription {
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
    font-family: 'Mada', serif;
    font-size: 14px;
    color: #39302c;
    border: 1px solid #b9b8b8;
    padding: 20px;
    background-color: #efefef;

    h2 {
      margin: 0;
      font-size: 24px;
    }

    .price {
      font-size: 19px;
      color: #1c1c1c;
      span {
        font-size: 40px;
      }
    }

    &.active {
      background-color: #22a18a;
      border-color: #22a18a;
      color: #fff;
      .price {
        color: #fff;
      }
    }

    @media (max-width: 768px) {
      h2 {
        font-size: 16px;
      }
      .price {
        font-size: 18px;
      }
      .duration,
      .message {
        font-size: 12px;
      }
    }
  }
}

.containerImageFormMobile {
  display: none;
}

/* Afficher la div sur les écrans mobiles (largeur <= 767px) */
@media (max-width: 767px) {
  .containerImageFormMobile {
    display: block;
  }
}

.imgFormMobile {
  height: 100%;
  width: 100%;
  object-fit: contain;

  @media (max-width: 767px) {
    height: auto;
    max-height: 50vh; /* Ajustez cette valeur si nécessaire pour s'assurer que l'image ne prend pas trop d'espace sur mobile */
    width: 100%;
    object-fit: cover;
  }
}

.imgForm {
  cursor: grab;
  position: fixed;
  top: 0;
  display: inline-block;
  transition-duration: 800ms;
  height: 100%;
  min-height: 850px;
  max-height: 1024px;
  width: 100%;
  max-width: 624px;

  @media (min-width: $breakpoint-laptop-m) {
    border-radius: 15px;
    margin-top: 30px;
    height: calc(100vh - 60px);
  }
  @media (max-height: 850px) {
    position: relative;
  }
  @media (max-width: 767px) {
    position: static;
    height: auto;
    max-height: 50vh; /* Adjust this value as needed to ensure the image doesn't take too much space on mobile */
    width: 100%;
    object-fit: cover;
  }
}

.textLogoBaladeParfume {
  cursor: grab;
  display: flex;
  align-self: center;
  width: 168px;
  margin: 7.81vh auto auto;
  @media (max-height: 1024px) {
    margin: 2.41vh auto auto;
  }
}
.titreRegister {
  font-weight: bold;
  line-height: 1.3;
  color: #1c1c1c;
  font-size: 2.5rem;
  font-family: 'Amiko', serif;
  margin: 8.59375vh 0 0;
}
.textOverProgressBar {
  font-family: 'Mada', serif;
  font-size: 1rem;
  color: #1c1c1c;
}
.step4Register {
  .infoEvent {
    color: white;
    position: relative;
    font-weight: bold;
    background: #22a18a;
    padding: 2px 0px 2px 30px;
    span {
      position: absolute;
      left: 6px;
      top: 1px;
    }
  }
  img {
    width: 56px;
    height: 56px;
    margin-bottom: 18px;
  }
  p {
    margin-bottom: 7px;
  }
}
.hidden {
  opacity: 0 !important;
}
.tes {
  opacity: 1;
  transition: opacity 1s;
  .sendBtnCode {
    height: 49px;
    border-radius: 8px;
    padding: 0;
    margin: 0;
    font-size: 13px;
    width: 58px;
    text-align: center;
    font-family: mada, serif;
    color: #22a18a;
    background-color: white;
    outline: 1px solid #22a18a;
    font-weight: bold;
    &:hover {
      color: white;
      background-color: #22a18a;
    }
  }
  input {
    height: 72px;
    width: 56px;
    background: #efefef;
    text-align: center;
    font-size: 25px;
    border-radius: 8px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  span {
    color: #000000;
    font-family: 'Amiko', serif;
    font-size: 23px;
    font-weight: bold;
    margin-left: 13px;
    margin-right: 13px;
  }
  position: relative;
  width: 100px;
  overflow: hidden;
}
.textImput {
  font-family: 'Amiko', serif;
  font-weight: bold;
  font-size: 1.43rem;
  color: #1c1c1c;
}

.containerImageForm {
  width: 100%;
  height: 100%;
  max-width: 624px;
  @media (max-width: $breakpoint-laptop - 1) {
    display: none;
  }
}
.containerRegister {
  margin: 0 30px;
  .errorMessage {
    float: left;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 6px;
  }
  @media (min-width: $breakpoint-laptop-m) {
    margin: 0 0 0 136px;
  }
}
.containerProgressBar {
  position: relative;
  width: calc(100% - 100px); // 100PX is countStep
  height: 20px;
}
.containerInput {
  margin-top: 64px;
}
.inputRegister {
}
.countStep {
  font-family: 'Amiko', serif;
  font-weight: bold;
  font-size: 1rem;
  color: #000000;
  position: absolute;
  width: 35px;
  top: -9px;
  right: -65px;
}
.login-container {
  background-color: white !important;
}

.login-form-container {
  display: flex;
  align-items: center;

  height: 100vh;
  @media (min-width: $breakpoint-laptop-m) {
    justify-content: center;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.barBackground {
  background-color: #efefef;
  border-radius: 8px;
  height: 8px;
}
.barCompleted {
  background-color: #22a18a;
  border-radius: 8px;
  height: 8px;
}
.formInputLogin {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  //background: red;
  border-radius: 15px;

  @media (min-width: $breakpoint-laptop) {
    max-width: 816px;
  }

  @media (min-width: 768px) {
    padding: 30px;
  }
}

.containerRelative {
  position: relative;
  min-width: 220px;
  width: 100%;
}
.inputGrey {
  background-color: #efefef;
  border-radius: 8px;
  height: 56px;
  margin-bottom: 5px;
  padding-left: 50px;
  padding-right: 50px;
  width: calc(100% - 100px); // remove padding in length
  color: #39302c;
  @media screen and (max-width: 720px) {
    font-size: 12px;
    width: 100%;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #39302c;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder {
    color: #39302c;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #39302c;
  }
}
.imgInputIcon {
  position: absolute;
  background-color: #bde3dc;
  padding: 7px;
  border-radius: 4px;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.iconRight {
  position: absolute;
  padding: 7px;
  border-radius: 4px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.relative {
  position: relative;
}
.selectIcon {
  align-items: center;
}

.buttonNext {
  background-color: #22a18a;
  color: white;
  float: right;
  border-radius: 8px;
  height: 48px;
  font-size: 19px;
  margin-bottom: 1vh;
  width: 120px;
  text-align: center;
}
.send {
  padding: 3px;
  background-color: #22a18a;
  color: white;
  float: right;
  border-radius: 8px;
  height: 28px;
  font-size: 19px;
  margin-bottom: 1vh;
  width: 120px;
  text-align: center;
  &:disabled {
    background-color: dimgrey;
    color: linen;
    opacity: 1;
  }
}
.sizeButtonLogin {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
}
.textBottom {
  @media (min-height: 900px) and (max-height: 1000px) {
  }
  width: 100%;
  text-align: center;
}
.needAccount {
  margin-top: 285px;
}
.textBottomLink {
  color: #22a18a;
  text-decoration: underline;
}

.buttonPrev {
  color: #22a18a;
  font-family: 'Amiko', serif;
  font-weight: bold;
}
.disabled {
  background-color: #607d8b80;
}
.containerLog {
  .swiper {
    overflow: visible;
    overflow-x: hidden;
  }
}
.symbolic {
  width: calc(100% + 80px);

  button {
    position: absolute;
    right: -40px;
    top: 0px;
    font-weight: bold;
    text-align: center;
    color: #22a18a;
    border: 1px solid #22a18a;
    padding: 0px 3px 1px 3px;
    border-radius: 8px;
    &:hover {
      background-color: #22a18a;
      color: white;
    }
  }
  img {
    float: left;
    height: 50px;
    border: 2px solid #bde3dc;
    border-radius: 8px;
  }
  .flagranceType {
    font-family: Mada, serif;
    color: #22a18a;
    font-size: 14px;
    white-space: nowrap; /* Empêche le texte de passer à la ligne */
    overflow: hidden; /* Masque le texte dépassant de la limite de largeur */
    text-overflow: ellipsis; /* Ajoute les points de suspension */
  }
  .nameFragrance {
    color: #39302c;
    font-size: 17px;
    font-weight: bold;
    font-family: Amiko, serif;
    padding-left: 7px;
    white-space: nowrap; /* Empêche le texte de passer à la ligne */
    overflow: hidden; /* Masque le texte dépassant de la limite de largeur */
    text-overflow: ellipsis; /* Ajoute les points de suspension */
  }
}
.signUpSearchBar {
  position: relative;
  width: calc(100% - 100px);
  max-height: 64px;
  input {
    background: #efefef;
    border: none;
    height: 35px;
  }
  ul {
    max-height: 160px;
    overflow: hidden;
    z-index: 9;
    position: relative;
  }
}
.inputOdeursEnfance {
  width: calc(100% - 20px);
  padding: 10px;
  background: #efefef;
  display: block;
  border: none;
  height: 64px;
  border-radius: 8px;
  &:disabled {
    cursor: default;
  }
  color: #39302c;
  resize: none;
}
h5 {
  white-space: nowrap;
}
