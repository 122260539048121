.containerDashMob {
  @media (max-width: 700px) {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
.container-lg {
  @media (max-width: 700px) {
    margin: 0;
    padding: 0;
  }
}
.px-3 {
  @media (max-width: 700px) {
    padding-left: 0 !important; /* Définissez la nouvelle valeur de padding-left */
    padding-right: 0 !important; /* Définissez la nouvelle valeur de padding-right */
  }
}
.hamburgerButtonIconsMob {
  color: #fff;
}

.header {
  display: flex;
  background-color: #22a18a;
  color: #fff;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding-bottom: 360px;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: 24px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding: 14px;
  /* overflow: scroll */
}

.content-inner {
  width: 100%; /* Définit la largeur du contenu */
  max-width: 1000px; /* Vous pouvez ajuster cette valeur selon vos besoins */
}

.footer {
  background-color: transparent;
  text-align: center;
  padding: 10px 0;
}

.buttonRight {
  width: 40px;
  height: 40px;
  background-color: #bde3dc;
  border-radius: 8px;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  margin-right: 14px;
  gap: 14px;
}

.avatarInfos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  padding-right: 14px;
  padding-left: 10px;
  .nameUser {
    flex: 1;
  }
  .avatarUser {
  }
}

.contentRessenti {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 10px;
}

.containerImgAppPhoto {
  display: flex;
  height: 25px;
  width: 25px;
  background-color: #bde3dc;
  border-radius: 4px;
  align-self: flex-end;
  margin-left: -20px;
  align-items: center;
  justify-content: center;
}

.tagMob {
  color: #bde3dc;
  background-color: #22a18a;
  border-radius: 50px;
  padding: 4px 8px 4px 8px;
}

.cardSymboliqueDash {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
  padding-top: 10px;
  padding-left: 14px;
  padding-bottom: 17px;
  width: 100%;
  margin-top: -140px;
  z-index: 99;
  img {
    float: left;
    border: 2px solid #bde3dc;
    border-radius: 8px;
    width: 70px;
    height: 70px;
  }
  .nameFragrance {
    color: #39302c;
    font-family: 'Amiko';
    /*  padding-top: 10px;
    margin-left: 75px; */
    font-size: 13px;
  }

  .flagranceType {
    font-family: 'Mada';
    color: #22a18a;
    font-size: 14px;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #22a18a;
}

.renewal {
  font-size: 16px;
  font-weight: normal;
  font-family: Mada, serif;
  color: white;
}
.containerMob {
  background-color: #22a18a !important;
  display: flex;
  flex-direction: column;
  .subscriptionInfo {
    display: flex;

    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    @media (max-width: 450px) {
      flex-direction: column;
    }
  }
}
.tagMob {
  right: 0;
  width: fit-content;
  color: #22a18a;

  justify-self: flex-start;
  background-color: white;
  @media (max-width: 700px) {
    font-size: 12px;
  }
}
.subscriptionInfo {
}
.containerSubscription {
  background-color: #22a18a !important;
  display: flex;
  flex-direction: column;
  .subscriptionInfo {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    @media (max-width: 450px) {
      flex-direction: column;
    }
  }
  .renewal {
    font-size: 16px;
    font-weight: normal;
    font-family: Mada, serif;
    color: white;
  }
  .checkSubscription {
    font-family: Mada, serif;
    font-weight: Bold;
    font-size: 16px;
    color: white;
    cursor: pointer;
    padding-bottom: 10px;
  }
  p {
    font-family: Amiko, serif;
    font-weight: bold;
    font-size: 23px;
    line-height: 31px;
    vertical-align: Center;
    color: #ffffff;
    flex: 1;
    @media (max-width: 700px) {
      font-size: 21px;
    }
  }
  /* .tag {
    right: 0;
    width: fit-content;
    color: #22a18a;

    justify-self: flex-start;
    background-color: white;
    @media (max-width: 700px) {
      font-size: 12px;
    }
  } */

  .tag-red {
    right: 0;
    width: fit-content;
    color: 'red';

    justify-self: flex-start;
    background-color: white;
    @media (max-width: 700px) {
      font-size: 12px;
    }
  }
}

.checkSubscription {
  font-family: Mada, serif;
  font-weight: Bold;
  font-size: 16px;
  color: white;
  cursor: pointer;
  padding-bottom: 10px;
}

.buttonRight {
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
