
/* .left50 (0 to 90)*/
@for $i from 0 through 90 {
  .left-#{$i},
  .top-#{$i},
  .bottom-#{$i},
  .right-#{$i} {
    left: -#{$i}px;
  }

  .left#{$i},
  .top#{$i},
  .bottom#{$i},
  .right#{$i} {
    left: #{$i}px;
  }

  .fontSize#{$i}{
    font-size: #{$i}px;
  }
  .fontSize#{$i}p5{
    font-size: #{$i + 0.5}px;
  }
  .width#{$i}{
    width: #{$i}px;
  }
}


@for $i from 0 through 90 {
  .margin#{$i} {
    margin: #{$i}px;
  }
  .marginTop#{$i} {
    margin-top: #{$i}px;
  }
  .marginLeft#{$i} {
    margin-left: #{$i}px;
  }
  .marginRight#{$i} {
    margin-right: #{$i}px;
  }
  .marginBottom#{$i} {
    margin-bottom: #{$i}px;
  }
}


@for $i from 0 through 90 {
  .padding#{$i} {
    margin: #{$i}px;
  }
  .paddingTop#{$i} {
    padding-top: #{$i}px;
  }
  .paddingLeft#{$i} {
    padding-left: #{$i}px;
  }
  .paddingRight#{$i} {
    padding-right: #{$i}px;
  }
  .paddingBottom#{$i} {
    padding-bottom: #{$i}px;
  }
}
