$scrollbar-width: 10px;
$scrollbar-track-color: #bde3dc;
$scrollbar-thumb-color: #22a18a;

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
.profil {
  .relative {
    position: relative;
    width: 100%;
  }
  .containerSubscription {
    background-color: #22a18a !important;
    display: flex;
    flex-direction: column;
    .subscriptionInfo {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      flex-direction: row;
      @media (max-width: 450px) {
        flex-direction: column;
      }
    }
    .renewal {
      font-size: 16px;
      font-weight: normal;
      font-family: Mada, serif;
      color: white;
    }
    .checkSubscription {
      font-family: Mada, serif;
      font-weight: Bold;
      font-size: 16px;
      color: white;
      cursor: pointer;
      padding-bottom: 10px;
    }
    p {
      font-family: Amiko, serif;
      font-weight: bold;
      font-size: 23px;
      line-height: 31px;
      vertical-align: Center;
      color: #ffffff;
      flex: 1;
      @media (max-width: 700px) {
        font-size: 21px;
      }
    }
    .tag {
      right: 0;
      width: fit-content;
      color: #22a18a;

      justify-self: flex-start;
      background-color: white;
      @media (max-width: 700px) {
        font-size: 12px;
      }
    }
    .tag-red {
      right: 0;
      width: fit-content;
      color: 'red';

      justify-self: flex-start;
      background-color: white;
      @media (max-width: 700px) {
        font-size: 12px;
      }
    }
  }
  /* Pour les navigateurs Webkit */
  ::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  ::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  /* Pour les autres navigateurs */
  /* Couleur de fond de la barre de défilement */
  ::-moz-scrollbar {
    width: $scrollbar-width;
    background-color: $scrollbar-track-color;
  }

  /* Couleur de la poignée */
  ::-moz-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  /* Couleur de fond de la piste */
  ::-moz-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  textarea {
    &:disabled {
      cursor: default;
    }
    resize: none;
    border: none;
    width: 100%;
    display: block;
    height: calc(100% - 70px);
    overflow: auto;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .textAreaEdit {
    border-width: 0 0 0 2px;
    border-style: solid;
    border-color: #22a18a;
    border-radius: 15px;
    padding: 0px 10px;
  }
  .headerProfil {
    img {
      height: 56px;
      width: 56px;
      border-radius: 8px;
      display: inline-block;
      margin-right: 6px;
    }
    .containerName {
      display: inline-block;
      p {
        font-size: 16px;
        color: #1c1c1c;
        font-family: Mada, serif;

        position: relative;
        top: 15px;
      }
      span {
        font-size: 23px;
        font-family: Amiko, serif;
        font-weight: bold;
      }
    }
    .buttonRight {
      float: right;
      margin-left: 35px;
      cursor: pointer;
      &:hover {
        p {
          text-decoration: underline;
        }
      }
      p {
        margin-top: 8px;
        color: #22a18a;
        font-size: 12px;
        font-family: Mada-Regular, serif;
        text-align: center;
      }
    }
  }
  .containerContent {
    background-color: white;
    border-radius: 16px;
    padding: 25px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
    position: relative;
    height: 100%;
    .addTag {
      position: absolute;
      right: -10px;
      top: -5px;
      height: 24px;
      width: 24px;
      border: none;
      cursor: pointer;
    }
    .symbolique {
      #buttonEdit {
        position: absolute;
        right: 20px;
        top: 10px;
        border: 0px solid #22a18a;
        padding: 3px 6px;
        border-radius: 5px;
        color: #22a18a;
        font-weight: bold;
        height: 43px;
        width: 49px;
        cursor: pointer;
      }
      .title {
        height: 30px;
        @media (min-width: 1540px) {
          margin-bottom: 25px;
        }
        @media (max-width: 1280px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
        @media (max-width: 880px) {
          margin-bottom: 15px;
          font-size: 16px;
        }
      }
      ul {
        height: 200px;
        z-index: 9;
        position: relative;
        overflow: auto;
      }
    }
    .containerContent {
      .title {
        height: 30px;
        @media (max-width: 1280px) {
          font-size: 20px;
        }
      }

      h5 {
        height: 80%;
        overflow: auto;

        p {
          // Styles pour le contenu du paragraphe
        }

        .footerContainerContent {
          height: 30px;
          // Styles pour le conteneur de pied de page
          .tag {
            // Styles pour la balise span avec la classe "tag"
          }
        }
      }
    }
    img {
      float: left;
      border: 2px solid #bde3dc;
      border-radius: 8px;
      width: 85px;
      height: 85px;
      @media (max-width: 880px) {
        margin-top: 8px;
        width: 65px;
        height: 65px;
      }
    }
  }
  .containerTag {
    display: flex;
    flex-wrap: wrap;
  }
  .title {
    margin-bottom: 0;
    color: #22a18a;
    font-size: 23px;
    font-weight: bold;
    font-family: 'Amiko', serif;

    @media (max-width: 1280px) {
      font-size: 20px;
    }
    @media (max-width: 880px) {
      font-size: 16px;
    }
  }
  .tag {
    position: relative;
    margin-top: 2px;
    color: #bde3dc;
    font-size: 14px;
    letter-spacing: 4px;
    font-family: 'Mada', serif;
    padding: 5px 10px;
    background-color: #22a18a;
    text-transform: uppercase;
    border-radius: 50px;
    margin-right: 10px;
    font-weight: 400;
    .btn {
      top: -3px;
      position: absolute;
      right: -4px;
      color: red;
      border: 1px solid red;
      border-radius: 15px;
      width: 15px;
      font-size: 12px;
      vertical-align: middle;
      text-align: center;
      align-items: center;
      height: 17px;
      cursor: pointer;
      font-weight: bold;
      background: white;
      padding: 0;
      padding-left: 3px;
    }
  }
  .flagranceType {
    font-family: 'Mada';
    color: #22a18a;
    font-size: 14px;
  }
  .nameFragrance {
    color: #39302c;
    font-size: 19px;
    font-weight: bold;
    font-family: 'Amiko';
    padding-top: 25px;
    margin-left: 110px;
    @media (max-width: 1380px) {
      font-size: 16px;
    }
    @media (max-width: 880px) {
      margin-left: 75px;
      font-size: 13px;
    }
  }
  p {
    color: #39302c;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Mada', serif;
    @media (max-width: 850px) {
      font-size: 13px;
    }
  }
  .footerContainerContent {
    position: absolute;
    font-weight: bold;
    bottom: 5px;
    @media (max-width: 850px) {
      font-size: 16px;
    }
  }
}
