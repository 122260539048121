.containerCheckList {
  @media (max-width: 700px) and (hover: none) and (pointer: coarse) {
    background-color: #f4faf9;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.hamburgerButtonIconsMob {
  color: #fff;
}

.headercheckListMobile {
  display: flex;
  margin-top: 50px;

  .containerIconMobile {
    background-color: #bde3dc;
    display: flex;
    flex: 1;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 10px;
    img {
      width: 20px;
      height: 16px;
    }
  }

  .containerContentMobile {
    margin: 0 0 0 13px;
    .titleChecklist {
      color: #1c1c1c;
      font-weight: bold;
      font-family: 'Amiko', serif;
    }
    p {
      font-family: 'Mada', serif;
      font-size: 15px;
      color: #39302c;
    }
  }
}
.containerTool {
  margin-top: 20px;
  .containerRelative {
    display: flex;
  }
  .imgInputIcon {
    background-color: transparent;
    top: 45%;
  }
}

.title {
  font-size: 24px;
}

.contentChecklist {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
  /* overflow: scroll */
}

.content-inner {
  width: 100%; /* Définit la largeur du contenu */
  max-width: 1000px; /* Vous pouvez ajuster cette valeur selon vos besoins */
}

.footer {
  background-color: transparent;
  text-align: center;
  padding: 10px 0;
}

.cardSymbolique {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding-top: 10px;
  padding-left: 14px;
  padding-bottom: 17px;
  align-items: center;
  .nameFragrance {
    color: #39302c;
    font-family: 'Amiko';
    padding-top: 10px;
    margin-left: 10px;
    font-size: 13px;
  }

  .flagranceType {
    font-family: 'Mada';
    color: #22a18a;
    font-size: 14px;
  }
}

.imgCard {
  border: 2px solid #bde3dc;
  border-radius: 8px;
  height: 94px;
  width: 82px;
}

.custom-checkbox input[type='checkbox'] {
  /* Masquer la case à cocher par défaut */
  display: none;
}

/* Style de l'apparence de la case à cocher personnalisée */
.custom-checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 25px; /* Espace pour afficher la coche */
  cursor: pointer;
  top: -14px;
}

/* Style de la boîte de la case à cocher */
.custom-checkbox label:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #22a18a; /* Couleur de la bordure */
  border-radius: 4px;
}

/* Style de la coche (affichée lorsque la case est cochée) */
.custom-checkbox input[type='checkbox']:checked + label:before {
  background-color: #22a18a; /* Couleur de fond lorsque cochée */
}

/* Style de la coche (affichée en blanc) */
.custom-checkbox label:after {
  content: '✓'; /* Code Unicode pour le V de validation */
  display: none;
  position: absolute;
  left: 4px;
  top: 3px;
  color: white; /* Couleur de la coche */
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
}

/* Afficher la coche lorsque la case est cochée */
.custom-checkbox input[type='checkbox']:checked + label:after {
  display: block;
}

.tagChecklist {
  padding: 10;
  background-color: #22a18a;
}

.date {
  padding-left: 10px;
  color: #39302c;
  font-size: 12px;
  font-family: Mada, serif;
  .tag {
    color: #bde3dc;
    background-color: #22a18a;
    border-radius: 50px;
    padding: 4px 8px 4px 8px;
    letter-spacing: 3px;
    text-align: center;
  }
  .try {
    font-family: Mada, serif;
    background-color: #822e66;
    color: #ece0e8;
  }
}

.containerButtonAddFlagranceModile {
  .buttonAddFlagranceMobile {
    /* background-color: white;
    border-radius: 15px;
    height: 10vh;
    border: 2px solid #22A18A;
    position: relative;
    bottom: -12.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      cursor: pointer;
      color:#22A18A;
      font-size: 23px;
      font-weight: bold;
      font-family: Amiko, serif;
      margin:0;
      img {
        background-color: #BDE3DC;
        padding: 10px;
        border-radius: 10px;
      }
    } */
  }
}
