.bgTransparent {
  background-color: transparent !important;
}
.fichesNotesParfum {
  .left img {
    width: 110px !important;
    object-fit: cover;
  }
  @media screen and (max-width: 480px) {
    .inputGrey {
      font-size: 12px;
      width: calc(100% - 90px);
    }
  }
  .iconEdit{
    position: absolute;
    cursor: pointer;
    padding: 0;

    top: 25px;
    right: 30px;
    height: auto;
    width: 33PX;
  }

  @media (max-width: 700px) {
    .iconEdit {
      padding: 0;
      position: absolute;
      top: 25px;
      right: 30px;
      height: auto;
      width: 33PX;
    }
    .description {
      width: 100%;
    }
    .textAreaEdit{
      width: calc(100% - 30px);
    }
    .modalViewEditor .textAreaEdit {
      border: 1px solid #22A18A;
      border-radius: 15px;
      padding: 8px;
    }
  }
  /* Pour les navigateurs Webkit */
  ::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  ::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  /* Pour les autres navigateurs */
  /* Couleur de fond de la barre de défilement */
  ::-moz-scrollbar {
    width: $scrollbar-width;
    background-color: $scrollbar-track-color;
  }

  /* Couleur de la poignée */
  ::-moz-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  /* Couleur de fond de la piste */
  ::-moz-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  .textAreaEdit {
    border-width: 0 0 0 2px;
    border-style: solid;
    border-color: white;
    border-radius: 15px;
    padding-left : 30px !important;
    height: 100%;

  }
  .modalViewEditor .textAreaEdit {
    border: 1px solid #22A18A;
    border-radius: 15px;
    padding: 8px;
  }
  .buttonAddInWishlist{
    background-color: white;
    border-radius: 15px;
    height: 60px;
    border: 2px solid #22A18A;
    width: 50%;
    margin-left: 25%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    font-family: Mada, serif;
    font-weight: bold;

    @media (max-width: 700px)
    {
      font-size: 14px;
      width: 100%;
      margin-left: 0px;
    }
    img {
      margin-right: 3px;
    }
      &:hover{
        background-color: #22A18A;
        color: white;
        font-weight: bold;
      }
  }
  .description {
    padding: 20px;
    padding-top: 0px;
    text-align: center;
    position: relative;
    word-break: break-word;
  }
  .containerBoxFlagrance{
    width: 100%;
    border-radius: 18px;
    height: fit-content;
    margin-bottom: 10px;

    .container {
      display: flex;
      position: relative;
      flex-direction: column;
    }

    .row {
      border-radius: 15Px;
      background: #22A18A;
      margin: 9px;
      padding: 9px;
      display: flex;
    }
    .sep{
      width: calc(100% - 60px);
      left: 30px;
      height:2px;
      background: #1c1c1c;
      top:50px;
      position: absolute;
    }

    .element {
      flex: 1;
      padding: 10px;
      @media (max-width: 700px)
      {
        padding: 0px;
      }
      margin: 5px;
    }
    .description {
      color : white;
      font-family: Mada, serif;
      line-height: 19px;
      text-align: left;
      width: calc(100% - 30px);
      margin-top: 5px;

      white-space: pre-wrap;
    }

    .title {
      color: white;
      margin: 0;
      font-family: Amiko, serif;
      font-weight: bold;
      font-size: 23px;
      margin-bottom: 10px;
      @media (max-width: 700px) {
        font-size: 16px;
      }
    }
.icon{
  float: left;
  background-color: #BDE3DC;
  height: 40px;
  width: 40px;
  padding-top: 6px;
  border-radius: 8px;
}
    @media (max-width: 700px) {
      .container {
        flex-direction: column;
      }
      .row {
        flex-direction: column;
      }
      .sep {
        display: none;
      }
    }
  }

  .containerCard {
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 10px;
    width: 100%; /* ajustez la largeur selon vos besoins */
    border-radius: 16px;
    background-color: #F4FAF9;
    overflow: hidden; /* assurez-vous que les images ne débordent pas de la carte */
    display: flex; /* affiche les éléments en ligne */
    flex-direction: row; /* aligne les éléments horizontalement */
    justify-content: space-between; /* espace les éléments équitablement */
    align-items: center; /* centre les éléments verticalement */
    .containerImgFlagrance{
    }
    .containerImgFlagrance img {
      border: 2px solid #BDE3DC;
      border-radius: 8px;
      width:145px;
      height:110px;
    }

    h5 {
      text-align: center;
      font-size: 16px;
      margin-top: 8px;
    }

    .icons {
      display: flex;
      justify-content: flex-end;

      img {
        cursor: pointer;
      }
    }
  }

  .containerCard {
    padding-right: 8%;
    padding-left: 8%;
    padding-bottom: 10px;
    width: 100%;
    border-radius: 16px;
    background-color: #F4FAF9;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .containerCard .description {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    color: #555555;
  }

  .containerCard .containerImgFlagrance {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
  }

  .containerCard h5 {
    margin-top: 10px;
    font-size: 15px;
    color: #333333;
    word-break: break-all; /* Nouvelle propriété ajoutée */
  }

  .containerCard img {
    width: 200px;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
  }

  .containerTool {
    .containerRelative {
      width: 40%;
    }
    .imgInputIcon{
      background-color: transparent;
      top : 45%;
    }
  }
  .headercheckList {
    display: flex;
    margin-top: 50px;
    .containerIcon {
      background-color: #BDE3DC;
      height: 48px;
      width: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .containerContent {
      margin: 2px 0 0 13px;
      h5 {
        color: #1C1C1C;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Amiko', serif;

        @media screen and (max-width: 480px) {
          font-size: 15px;
        }
      }
      p {
        font-family: 'Mada', serif;
        font-size: 15px;
        color: #39302C;

        @media screen and (max-width: 480px) {
          font-size: 13px;
        }
      }
    }
  }

  .containercheckListContent {
    padding: 20px;
    border-radius: 16px;
    background-color: white;
    width: 100%;
    position: relative;

    .checkListContent {
      display: flex;
      flex-wrap: wrap;
      height: 65vh;
      background-color: white;
      border-radius: 16px;
      padding: 18px 18px 20vh 18px;
      @media screen and (max-width: 480px) {
        padding: 3px;
      }

      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
      overflow: auto;
      .active {
        border: 1px solid #22A18A;
      }
      .boxFlagrance {
        border: 1px solid #22A18A;
        display: flex;
        align-items: center;
        padding-left:20px;
        padding-right:20px;
        .right{
          height: 100%;
          display: flex;
          width: 40%;
          justify-content: flex-end;
          align-items: center;
          .containerImg{
            background-color: #BDE3DC;
            height: 40px;
            width: 40px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor:pointer;

            img {
              height: 20px;
              width:22px;
              border:0;
            }
          }
          .sep{

            background: #22A18A;
            height: 60%;
            width: 1px;
            margin-left: 30px;
            margin-right: 30px;
          }
          .date {
            padding-left:10px;
            color: #39302C;
            font-size: 14px;
            font-family: Mada, serif;
            margin: 0 5%;
            .tag {
              color: #BDE3DC;
              background-color: #22A18A;
              border-radius: 50px;
              padding:4px 8px 4px 8px;
              letter-spacing: 3px;
            }
            .try {
              font-family: Mada, serif;
              background-color: #822E66;
              color: #ECE0E8;
            }
          .inProgress {
            font-family: Mada, serif;
            background-color : #F8FFA6;
            color: #22A18A;
          }
            .endTry{
              font-family: Mada, serif;
              background-color : #BDE3DC;
              color: #22A18A;
            }
          }
        }

        background-color: #F4FAF9;
        width: 100%;
        height: 144px;
        border-radius: 16px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
      }
      .left {
        width: 60%;
        display: flex;
        align-items: center;
        /* Style par défaut de la case à cocher */
        .custom-checkbox input[type="checkbox"] {
          /* Masquer la case à cocher par défaut */
          display: none;
        }

        /* Style de l'apparence de la case à cocher personnalisée */
        .custom-checkbox label {
          display: inline-block;
          position: relative;
          padding-left: 25px; /* Espace pour afficher la coche */
          cursor: pointer;
          top: -14px;
        }

        /* Style de la boîte de la case à cocher */
        .custom-checkbox label:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          border: 2px solid #22A18A; /* Couleur de la bordure */
          border-radius: 4px;
        }

        /* Style de la coche (affichée lorsque la case est cochée) */
        .custom-checkbox input[type="checkbox"]:checked + label:before {
          background-color: #22A18A; /* Couleur de fond lorsque cochée */
        }

        /* Style de la coche (affichée en blanc) */
        .custom-checkbox label:after {
          content: '✓'; /* Code Unicode pour le V de validation */
          display: none;
          position: absolute;
          left: 4px;
          top: 3px;
          color: white; /* Couleur de la coche */
          font-size: 14px;
          line-height: 1;
          font-weight: bold;
        }

        /* Afficher la coche lorsque la case est cochée */
        .custom-checkbox input[type="checkbox"]:checked + label:after {
          display: block;
        }


        img {
          border: 2px solid #BDE3DC;
          border-radius: 8px;
          width:110px;
          height:110px;
          margin-left: 6%;

        }
        .infoFlarance {
          position: relative;
          padding-left: 20px;
          width: 100%;
          word-break: break-all;
          white-space: break-spaces;
          max-width: 305px;
          .imgFlagranceList {
            margin-bottom: 0px!important;
            margin-top: 9px!important;
          }
          .deleteBtn {
            position: absolute;
            left: 13px;
            background: white;
            top: 0px;
            padding: 1px 4Px;
            border-radius: 6px;
            color: rgb(34, 161, 138);
            border: 1px solid rgb(34, 161, 138);
            &:hover {
              background: red;
              color: white;
            }
          }
          input {
            border : 2px solid #BDE3DC;

            z-index: 0;
          }
          ul {
            position: absolute;
            width: calc(100% );
            max-height: 170px;
            overflow: auto;
            z-index: 1;
            li {
              width: 100%;
            }
          }
          span {
            height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          h5 {
            color:#1C1C1C;
            font-size: 25px;
            font-family: Amiko, serif;
            word-break: break-all;
            white-space: break-spaces;

            @media screen and (max-width: 1080px) {
              font-size: 24px;
            }

            @media screen and (max-width: 720px) {
              font-size: 13px;
            }

            @media screen and (max-width: 480px) {
              font-size: 12px;
            }

          }
          p{
            color : #22A18A;
            font-family: Mada, serif;
            font-size: 14px;
          }
        }

      }


      /* Barre de défilement personnalisée */
      &::-webkit-scrollbar {
        width: 4px;
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #B9B8B8;
        border-radius: 2px;
      }
    }
  }
  .containerButtonAddFlagrance {
    position: absolute;
    z-index: 1;
    background: linear-gradient(to bottom, transparent -85%, rgb(248,250,249) 100%);
    bottom: 0;
    border-radius: 15px;
    width: calc(100% - 30px);
    .bg {

      background: white;
    }
    .buttonAddFlagrance {
      background-color: white;
      border-radius: 15px;
      height: 10vh;
      border: 2px solid #22A18A;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;

      p {
        cursor: pointer;
        color:#22A18A;
        font-size: 23px;
        font-weight: bold;
        font-family: Amiko, serif;
        margin:0;
        img {
          background-color: #BDE3DC;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }
  .iconAddAndTrash {
    display: flex;
    height: 100%;
    gap: 35px;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 780px)
    {
      gap: 3px;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  @media (max-width: 768px) {
    .buttonAddFlagrance{
      p {

        font-size: 15px !important;
      }
    }
    .boxFlagrance {
      flex-direction: column;
      height: fit-content !important;
      min-height: 85px;
      padding: 10px !important;
      p {
        margin-bottom: 0;
      }
      .containerImg {
        margin-bottom: 6px;
        width: 100% !important;
        gap: 80% !important;
      }
      .left {
        width: 100% !important;
        order: 1;
      }
      .right {
        width: 100% !important;
        margin-top: 20px;
        order: 2;
        height: 0 !important;
      }
    }
  }
}
