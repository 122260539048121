.layerings {
  /* Pour les navigateurs Webkit */
  ::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  ::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  /* Pour les autres navigateurs */
  /* Couleur de fond de la barre de défilement */
  ::-moz-scrollbar {
    width: $scrollbar-width;
    background-color: $scrollbar-track-color;
  }

  /* Couleur de la poignée */
  ::-moz-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  /* Couleur de fond de la piste */
  ::-moz-scrollbar-track {
    background-color: $scrollbar-track-color;
  }
  .textAreaEdit {
    border-style: solid;
    padding-left : 30px !important;
    border: 2px solid #BDE3DC;
    border-radius: 6px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .description {
    padding: 20px;
    padding-top: 0px;
    word-break: break-all;
    white-space: break-spaces;
    text-align: left;
  }
  .containerCard {
    padding-top: 15px;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 10px;
    width: 100%; /* ajustez la largeur selon vos besoins */
    border-radius: 16px;
    background-color: #F4FAF9;
    overflow: hidden; /* assurez-vous que les images ne débordent pas de la carte */
    display: flex; /* affiche les éléments en ligne */
    flex-direction: row; /* aligne les éléments horizontalement */
    justify-content: space-between; /* espace les éléments équitablement */
    align-items: center; /* centre les éléments verticalement */
    .containerImgFlagrance{
      div {
        margin-top: 0px;
      }
      ul {
        max-height: 200px;
        overflow: auto;
        position: absolute;
        z-index: 2;
      }
      input {
        width: calc(100% - 31px);
        padding: 10px 12px;
        margin-left : 7px;
      }
    }
    .containerImgFlagrance img {
      border: 2px solid #BDE3DC;
      border-radius: 8px;
      width:110px;
      height:110px;
    }

    h5 {
      text-align: center;
      font-size: 16px;
      min-height: 36px;
      margin-top: 8px;
      white-space: break-spaces;
      @media screen and (max-width: 720px) {
        font-size: 11px;
        height: 21px;
      }
    }

    .icons {
      display: flex;
      justify-content: flex-end;

      img {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .buttonAction {
    width: 100%;
    position: relative;
    height: 32px;
  }
  .containerImgEdit{
    background-color: #BDE3DC;
    display: flex; /* Ajout */
    justify-content: center; /* Ajout */
    align-items: center; /* Ajout */
    position: absolute;
    left: calc(100% - 94px);
    bottom: 10px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    border-radius: 8px;
img{
  height: 32px;
  width: 32px;
}
  }

  .containerImgTrash{
    height: 32px;
    width: 32px;
    border-radius: 8px;
    position: absolute;
    bottom: 10px;
    left: calc(100% - 50px);
    background-color: #EFEFEF;
    display: flex; /* Ajout */
    justify-content: center; /* Ajout */
    align-items: center; /* Ajout */
    cursor: pointer;

    &:hover {
      background-color: #BDE3DC;
      .iconTrash{
        filter: grayscale(0);
      }
    }
    .iconTrash {
      filter: grayscale(1);
    }
  }

  .containerCard {
    padding-right: 8%;
    padding-left: 8%;
    padding-bottom: 10px;
    width: 100%;
    border-radius: 16px;
    background-color: #F4FAF9;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .containerCard .description {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    color: #555555;
  }

  .containerCard .containerImgFlagrance {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
  }

  .containerCard h5 {
    margin-top: 10px;
    font-size: 15px;
    color: #333333;
   // word-break: break-all; /* Nouvelle propriété ajoutée */
  }

  .containerCard img {
    width: 200px;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
  }

  .containerTool {
    .containerRelative {
      width: 40%;
    }
    .imgInputIcon{
      background-color: transparent;
      top : 45%;
    }
  }
  .headerLayerings {
    display: flex;
    margin-top: 50px;
    .containerIcon {
      background-color: #BDE3DC;
      height: 48px;
      width: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .containerContent {
      margin: 2px 0 0 13px;
      h5 {
        color: #1C1C1C;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Amiko', serif;

      }
      p {
        font-family: 'Mada', serif;
        font-size: 15px;
        color: #39302C;
      }
    }
  }

  .containerLayeringsContent {
    padding: 20px;
    border-radius: 16px;
    background-color: white;
    width: 100%;
    position: relative;

    .layeringsContent {
      display: flex;
      flex-wrap: wrap;
      height: 70vh;
      background-color: white;
      border-radius: 16px;
      padding: 18px 18px 20vh 18px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
      overflow: auto;
      .boxFlagrance {
        display: flex;
        align-items: center;
        padding-left:20px;
        padding-right:20px;
        .right{
          height: 100%;
          display: flex;
          width: 30%;
          justify-content: flex-end;
          align-items: center;
          .containerImg{
            background-color: #BDE3DC;
            height: 40px;
            width: 40px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor:pointer;

            img {
              height: 20px;
              width:22px;
              border:0;
              &:hover {
                content: url("../../public/icon/iconHeartBreak.png");
              }
            }
          }
          .sep{

            background: #22A18A;
            height: 60%;
            width: 1px;
            margin-left: 30px;
            margin-right: 30px;
          }
          .date {
            padding-left:10px;
            color: #39302C;
            font-size: 14px;
            font-family: Mada, serif;
            .tag {
              color: #BDE3DC;
              background-color: #22A18A;
              border-radius: 50px;
              padding:4px 8px 4px 8px;
            }
          }
        }

        background-color: #F4FAF9;
        width: 100%;
        height: 144px;
        border-radius: 16px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
        margin-bottom: 20px;
      }
      .left {
        width: 70%;
        display: flex;
        align-items: center;
        img {
          border: 2px solid #BDE3DC;
          border-radius: 8px;
          width:110px;
          height:110px;

        }
        .infoFlarance {
          position: relative;
          padding-left: 20px;
          width: 100%;

          input {
            border : 2px solid #BDE3DC;

            z-index: 0;
          }
          ul {
            position: absolute;
            width: calc(100% - 20px);
            li {
              width: 100%;
            }
          }
          span {
            height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          h5 {
            color:#1C1C1C;
            font-size: 28px;
            font-family: Amiko, serif;
          }
          p{
            color : #22A18A;
            font-family: Mada, serif;
            font-size: 14px;
          }
        }

      }


      /* Barre de défilement personnalisée */
      &::-webkit-scrollbar {
        width: 4px;
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #B9B8B8;
        border-radius: 2px;
      }
    }
  }
  .containerButtonAddFlagrance {
    position: absolute;
    z-index: 1;
    background: linear-gradient(to bottom, transparent -85%, rgb(248,250,249) 100%);
    bottom: 0;
    border-radius: 15px;
    width: calc(100% - 30px);
    height: 23vh;
    .bg {

      background: white;
    }
    .buttonAddFlagrance {
      background-color: white;
      border-radius: 15px;
      height: 10vh;
      border: 2px solid #22A18A;
      position: relative;
      bottom: -12.5vh;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        cursor: pointer;
        color:#22A18A;
        font-size: 23px;
        font-weight: bold;
        font-family: Amiko, serif;
        margin:0;
        img {
          background-color: #BDE3DC;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .boxFlagrance {
      flex-direction: column;
      padding: 10px;
      height: 200px !important;
      .left {
        width: 100%;
        order: 1;
      }

      .right {
        width: 50% !important;
        order: 2;
        margin-top: 20px;
      }
    }
  }
}
