.selectDate {
  color: #39302c;
  height: 55px;
  font-weight: bold;
  font-size: 16px;
  font-family: Mada, serif;
  padding-top: 20px;
  cursor: pointer;
  width: fit-content;
  @media (max-width: 908px) {
    display: none;
  }
  @media screen and (max-width: 1080px) {
    font-size: 15px;
  }

  @media screen and (max-width: 720px) {
    font-size: 13px;
    height: 20px;
    padding-top: 0;
  }

  @media screen and (max-width: 480px) {
    height: 17px;
    font-size: 10px;
    padding-top: 0;
  }
}
.containerTool {
  .active {
    color: #22a18a;
    span {
      position: relative;
      bottom: -17px;
      left: -50%;
      height: 8px;
      width: 8px;
      border-radius: 4px;
    }
    span::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: #22a18a;
      border-radius: 50%;
    }
  }
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  font-size: 16px; /* Taille de police standard */
  zoom: reset; /* Réinitialiser le niveau de zoom */
  touch-action: manipulation; /* Désactiver le zoom tactile */
}
