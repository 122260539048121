// Here you can add other styles
// change background
.wrapper {
  background-color: #F4FAF9 !important;
}
.hamburgerButtonIcons {
  color: #22A18A;
  position:fixed;
}

.sidebar-toggler::before{
  background-image: var(--cui-sidebar-toggler-indicator-hover);
}
.hide {
  display: none;
}

.noteList{
  border-radius: 3px;
  position: absolute;
  bottom: -96px;
  z-index: 36;
  background: #baddd6;
  width: 145px;
  max-height: 80px;
  overflow-y: scroll;
  color: white;
  overflow-wrap: break-word;
  /* top: 28px; */
  li {
    cursor: pointer;
    border-bottom:1px;
    border-color: white;
    padding: 2px 6px 2px 6px;
    &:hover {
      background: #22A18A;
      font-weight: bold;
      color: white;
    }
  }
}
