.containerMp3 {
  width: 95%;
  position: absolute;
  bottom: -1px;
  background-color: #22a18a;
  height: 80px;
  border-radius: 16px 16px 0 0;
  padding-left: calc(4% + 20px);
  padding-top: 3px;
  padding-bottom: 20px;
  padding-right: calc(4% + 20px);

  h5 {
    margin-bottom: 16px;
    text-align: right;
    color: white;
  }
  .play {
    width: 18px;
    position: relative;
    left: 55%;
    top: -32%;
    cursor: pointer;
  }
  .iconVolume {
    position: relative;
    left: 61%;
    top: -32%;
  }
  .inputRangeVolume {
    position: relative;
    top: -62%;
    max-width: 20%;
    left: calc(65% + 40px);
  }
  .input-range__slider {
    background-color: white;
    border-color: white;
  }
  .input-range__label-container {
    color: white;
  }
  .input-range__track--active {
    background-color: #ffffff;
  }
  .input-range__track input-range__track--background {
    background-color: rgba(255, 255, 255, 0.5);
  }
  @media (min-width: 1250px) {
    .input-range__label--max .input-range__label-container {
      left: 133%;
      top: -22px;
    }
  }
}

.input-range {
  width: 50% !important;
}
.exclusiveContent {
  /* overflow: hidden;
  padding: 10px;
  position: relative;
  height: 100vh; */
  .card {
    max-width: 372px;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 16px;
    padding: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
    .mainImg {
      height: 205px;
      border-radius: 8px;
      margin-bottom: 20px;
    }
    span {
      margin-right: 10px;
    }
    h4 {
      margin-top: 20px;
      font-size: 18px;
      color: #1c1c1c;
      font-family: Amiko, serif;
      margin-left: 15px;
    }
    .description {
      white-space: -moz-pre-wrap;
      white-space: pre-wrap;
      white-space: -o-pre-wrap;
      white-space: -pre-wrap;
      word-wrap: break-word;
      margin-bottom: 40px;
      color: #39302c;
      font-family: Mada, serif;
      text-align: left;
    }
    .event {
      display: flex;
      align-items: center;
      height: 34px;
      justify-content: center;

      margin-right: 10px;
      cursor: pointer;
      font-family: Mada, serif;
      color: #22a18a;
      font-weight: bold;
      font-size: 18px;
      position: absolute;
      bottom: 0;
      right: 0;
      img {
        vertical-align: middle;
        margin: auto;
      }
    }
  }
  .card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-height: 70vh;
  }
  @media (max-width: 1250px) {
    .event {
      font-size: 16px !important;
    }
    .card-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 680px) {
    .event {
      font-size: 14px !important;
    }
    .card-grid {
      grid-template-columns: 1fr;
    }
  }

  .containerTag {
    display: flex;
    flex-wrap: wrap;
    .tag {
      margin-top: 4px;
      flex: 1 0 auto;
      cursor: pointer;
      color: #22a18a;
      border-radius: 50px;
      padding: 3px 11px 3px 11px;
      max-width: 160px;
      border: 1px solid #22a18a;
      text-align: center;
      margin-left: 10px;
      font-size: 18px;
    }
    .active {
      color: #bde3dc;
      background-color: #22a18a;
      cursor: default;
    }
  }
  .containerexclusiveContent {
    .containerTool {
      .active {
      }
    }
  }
  .description {
    padding: 20px;
    padding-top: 0px;
    text-align: center;
  }
  .containerCard {
    padding: 5% 20%;
    padding-bottom: 10px;
    width: 100%; /* ajustez la largeur selon vos besoins */
    border-radius: 16px;
    background-color: #f4faf9;
    overflow: hidden; /* assurez-vous que les images ne débordent pas de la carte */
    display: flex; /* affiche les éléments en ligne */
    flex-direction: row; /* aligne les éléments horizontalement */
    justify-content: space-between; /* espace les éléments équitablement */
    align-items: center; /* centre les éléments verticalement */

    .containerImgFlagrance img {
      border: 2px solid #bde3dc;
      border-radius: 8px;
      width: 110px;
      height: 110px;
    }

    h5 {
      text-align: center;
      font-size: 16px;
      margin-top: 8px;
    }

    .icons {
      display: flex;
      justify-content: flex-end;

      img {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .containerTool {
    .containerRelative {
      width: 40%;
    }
    .imgInputIcon {
      background-color: transparent;
      top: 45%;
    }
  }
  .headerExclusiveContent {
    display: flex;
    margin-top: 50px;
    padding-bottom: 6px;
    .containerIcon {
      background-color: #bde3dc;
      height: 48px;
      width: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .containerContent {
      margin: 2px 0 0 13px;
      h5 {
        color: #1c1c1c;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Amiko', serif;
      }
      p {
        font-family: 'Mada', serif;
        font-size: 15px;
        color: #39302c;
      }
    }
  }

  .containerexclusiveContent {
    padding: 20px;
    border-radius: 16px;
    width: 100%;
    position: relative;
    overflow: auto;
    /* Barre de défilement personnalisée */
    &::-webkit-scrollbar {
      width: 4px;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b9b8b8;
      border-radius: 2px;
    }

    .exclusiveContentContent {
      height: 70vh;
      background-color: white;
      border-radius: 16px;
      padding: 18px 18px 20vh 18px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
      overflow: auto;
      .boxFlagrance {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        .right {
          height: 100%;
          display: flex;
          width: 30%;
          justify-content: flex-end;
          align-items: center;
          .containerImg {
            background-color: #bde3dc;
            height: 40px;
            width: 40px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img {
              height: 20px;
              width: 22px;
              border: 0;
              &:hover {
                content: url('../../public/icon/iconHeartBreak.png');
              }
            }
          }
          .sep {
            background: #22a18a;
            height: 60%;
            width: 1px;
            margin-left: 30px;
            margin-right: 30px;
          }
          .date {
            padding-left: 10px;
            color: #39302c;
            font-size: 14px;
            font-family: Mada, serif;
            .tag {
              color: #bde3dc;
              background-color: #22a18a;
              border-radius: 50px;
              padding: 4px 8px 4px 8px;
            }
          }
        }

        background-color: #f4faf9;
        width: 100%;
        height: 144px;
        border-radius: 16px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
        margin-bottom: 20px;
      }
      .left {
        width: 70%;
        display: flex;
        align-items: center;
        img {
          border: 2px solid #bde3dc;
          border-radius: 8px;
          width: 110px;
          height: 110px;
        }
        .infoFlarance {
          position: relative;
          padding-left: 20px;
          width: 100%;

          input {
            border: 2px solid #bde3dc;

            z-index: 0;
          }
          ul {
            position: absolute;
            width: calc(100% - 20px);
            li {
              width: 100%;
            }
          }
          span {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          h5 {
            color: #1c1c1c;
            font-size: 28px;
            font-family: Amiko, serif;
          }
          p {
            color: #22a18a;
            font-family: Mada, serif;
            font-size: 14px;
          }
        }
      }

      /* Barre de défilement personnalisée */
      &::-webkit-scrollbar {
        width: 4px;
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b9b8b8;
        border-radius: 2px;
      }
    }
  }

  @media (max-width: 768px) {
    .boxFlagrance {
      flex-direction: column;
      padding: 10px;
      height: 200px !important;
      .left {
        width: 100%;
        order: 1;
      }

      .right {
        width: 50% !important;
        order: 2;
        margin-top: 20px;
      }
    }
  }
  @media (max-width: 508px) {
    padding: 10px 0 0 0;
  }
}
