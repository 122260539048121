.fragrance {
  .headerFragrance {
    display: flex;
    margin-top: 50px;
    .containerIcon {
      background-color: #BDE3DC;
      height: 48px;
      width: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .containerContent {
      margin: 2px 0 0 13px;
      h5 {
        color: #1C1C1C;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Amiko', serif;
      }
      p {
        font-family: 'Mada', serif;
        font-size: 15px;
        color: #39302C;
      }
    }
  }

  .containerWishListContent {
    padding: 20px;
    border-radius: 16px;
    background-color: white;
    width: 100%;
    position: relative;

    .wishListContent {
      height: 75vh;
      background-color: white;
      border-radius: 16px;
      padding: 18px 18px 20vh 18px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
      overflow: auto;
      .boxFlagrance {
        display: flex;
        align-items: center;
        padding-left:20px;
        padding-right:20px;
        .right{
          height: 100%;
          display: flex;
          width: 30%;
          justify-content: flex-end;
          align-items: center;
          .containerImg{
            background-color: #BDE3DC;
            height: 40px;
            width: 40px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor:pointer;
            padding: 20px;

            img {
              height: 20px;
              width:22px;
              border:0;
              &:hover {
                content: url("../../public/icon/iconHeartBreak.png");
              }
            }
          }
          .sep{

            background: #22A18A;
            height: 60%;
            width: 1px;
            margin-left: 30px;
            margin-right: 30px;
          }
          .date {
            padding-left:10px;
            color: #39302C;
            font-size: 14px;
            font-family: Mada, serif;
            .tag {
              color: #BDE3DC;
              background-color: #22A18A;
              border-radius: 50px;
              padding:4px 8px 4px 8px;
            }
          }
        }

        background-color: #F4FAF9;
        width: 100%;
        height: 144px;
        border-radius: 16px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
        margin-bottom: 20px;
      }
      .left {
        width: 70%;
        display: flex;
        align-items: center;
        padding-right: 60px;
        img {
          border: 2px solid #BDE3DC;
          border-radius: 8px;
          width:80px;
          height:96px;
        }
        .infoFlarance {
          position: relative;
          padding-left: 20px;
          width: 100%;

          input {
            border : 2px solid #BDE3DC;
            z-index: 0;
          }
          ul {
            position: absolute;
            width: calc(100% - 20px);
            height: 200px;
            overflow: auto;
            z-index: 10;
            li {
              width: 100%;
            }
          }
          span {
            height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          h5 {
            color:#1C1C1C;
            font-size: 28px;
            font-family: Amiko, serif;

            white-space: break-spaces;
            @media screen and (max-width: 720px) {
              font-size: 13px;
            }
          }
          p{
            color : #22A18A;
            font-family: Mada, serif;
            font-size: 14px;
            white-space: break-spaces;

            @media screen and (max-width: 720px) {
              font-size: 13px;
            }
          }
        }

      }


      /* Barre de défilement personnalisée */
      &::-webkit-scrollbar {
        width: 4px;
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #B9B8B8;
        border-radius: 2px;
      }
    }
  }
  .containerButtonAddFlagrance {
    position: absolute;
    z-index: 1;
    background: linear-gradient(to bottom, transparent -85%, rgb(248,250,249) 100%);
    bottom: 0;
    border-radius: 15px;
    width: calc(100% - 30px);
    height: 23vh;
    .bg {

      background: white;
    }
    .buttonAddFlagrance {
      background-color: white;
      border-radius: 15px;
      height: 10vh;
      border: 2px solid #22A18A;
      position: relative;
      bottom: -12.5vh;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        cursor: pointer;
        color:#22A18A;
        font-size: 23px;
        font-weight: bold;
        font-family: Amiko, serif;
        margin:0;
        @media screen and (max-width: 480px) {
          font-size: 13px;
        }
        img {
          background-color: #BDE3DC;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wishList .containerWishListContent .wishListContent .left .infoFlarance h5 {
      font-size: 10px;
    }
  .fichesNotesParfum .buttonAddFlagrance p {
    font-size: 15px !important;
  }
    .boxFlagrance {
      flex-direction: column;
      padding: 10px !important;
      p {
        margin-bottom: 0;
      }
      .containerImg {
        margin-bottom: 6px;
      }
      .left {
        width: 100% !important;
        order: 1;
        padding-right: 0;
        img{
          min-width: 70px !important;
          height: 70px !important;
        }
      }
      .right {
        width: 100% !important;
        margin-top: 20px;
        order: 2;
        height: 0 !important;
      }
    }
  }
}
