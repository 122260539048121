.bgTransparent {
  background-color: transparent !important;
}
.modalViewEditor {
  height: 80vh;
}
.ficheEssais {
  .iconAddAndTrash {

    display: flex;
    height: 100%;
    gap: 35px;
    flex-direction: column;
    justify-content: center;
    img {
      height: auto;
      width: 20px;
    }
    @media (max-width: 700px)
    {
      gap: 3px;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .messageAdd {
    text-align: center;
    font-size: 20px;
    margin-bottom: 8px;
    @media (max-width: 700px)
    {
      font-size: 14px;
    }
  }
  /* Pour les navigateurs Webkit */
  ::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  ::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  /* Pour les autres navigateurs */
  /* Couleur de fond de la barre de défilement */
  ::-moz-scrollbar {
    width: $scrollbar-width;
    background-color: $scrollbar-track-color;
  }

  /* Couleur de la poignée */
  ::-moz-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  /* Couleur de fond de la piste */
  ::-moz-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  .textAreaEdit {
    border-width: 0 0 0 2px;
    border-style: solid;
    border-color: #22A18A;
    border-radius: 15px;
    padding-left : 30px !important;
    height: 100%;
  }
  .modalViewEditor .textAreaEdit {
    border: 1px solid #22A18A;
    border-radius: 15px;
    padding: 8px;
  }
  .buttonAddInWishlist{
    background-color: white;
    border-radius: 15px;
    height: 60px;
    border: 2px solid #22A18A;
    width: 50%;
    margin-left: 25%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    font-family: Mada, serif;
    font-weight: bold;
    @media (max-width: 700px)
    {
      font-size: 14px;
      width: 100%;
      margin-left: 0px;
    }
    img {
      margin-right: 3px;
    }
      &:hover{
        background-color: #22A18A;
        color: white;
        font-weight: bold;
      }
  }
  .description {
    padding-top: 0px;
    text-align: center;
    position: relative;
    word-break: break-word;
    width: calc(100% - 60px);
    padding-left: 5px;
    cursor:initial;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    min-height: 68px;
  }
  .iconEdit{
    padding: 0;
    top: -4px;
    right: 3px;
    height: 35PX;
    width: 35PX;
    &:hover{
      cursor: pointer;
    }
  }
  @media (max-width: 700px) {
    .iconEdit {
      padding: 0;
      top: -34px;
      position: absolute;
      right: 3px;
      height: 26PX;
      width: 26PX;
    }
    .description {
      width: 100%;
    }
    .textAreaEdit{
        width: calc(100% - 30px);
    }
    .modalViewEditor .textAreaEdit {
      border: 1px solid #22A18A;
      border-radius: 15px;
      padding: 8px;
    }
  }
  .containerBoxFlagrance{
    width: 100%;
    border-radius: 18px;
    height: fit-content;
    margin-bottom: 10px;
    .container {
      display: flex;
      position: relative;
      flex-direction: column;
    }

    .row {
      display: flex;
    }
    .sep{
      height: calc(81% - 60px);
      width: 2px;
      background: #22A18A;
      left: 50%;
      top: 6%;
      position: absolute;
    }

    .element {
      flex: 1;
      padding: 10px;
      margin: 5px;
    }
    .description {
      color : #39302C;
      font-family: Mada, serif;
      line-height: 19px;
      text-align: left;
    }

    .title {
      margin: 0;
      font-family: Amiko, serif;
      color : #22a18a;
      font-weight: bold;
      font-size: 23px;
      @media (max-width: 700px) {
        font-size: 16px;
      }
      white-space: break-spaces;
    }
  .icon {
    float: left;
    background-color: #BDE3DC;
    height: 40px;
    width: 40px;
    padding-top: 6px;
    border-radius: 8px;
  }
    @media (max-width: 700px) {
      .icon{
        display: none;
      }
      .container {
        flex-direction: column;
      }
      .row {
        flex-direction: column;
      }
      .sep {
        display: none;
      }
    }
  }

  .containerCard {
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 10px;
    width: 100%; /* ajustez la largeur selon vos besoins */
    border-radius: 16px;
    background-color: #F4FAF9;
    overflow: hidden; /* assurez-vous que les images ne débordent pas de la carte */
    display: flex; /* affiche les éléments en ligne */
    flex-direction: row; /* aligne les éléments horizontalement */
    justify-content: space-between; /* espace les éléments équitablement */
    align-items: center; /* centre les éléments verticalement */
    .containerImgFlagrance{
    }
    .containerImgFlagrance img {
      border: 2px solid #BDE3DC;
      border-radius: 8px;
      width:145px;
      height:110px;
    }

    h5 {
      text-align: center;
      font-size: 16px;
      margin-top: 8px;
    }

    .icons {
      display: flex;
      justify-content: flex-end;

      img {
        cursor: pointer;
      }
    }
  }

  .containerCard {
    padding-right: 8%;
    padding-left: 8%;
    padding-bottom: 10px;
    width: 100%;
    border-radius: 16px;
    background-color: #F4FAF9;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .containerCard .description {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    color: #555555;
  }

  .containerCard .containerImgFlagrance {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
  }

  .containerCard h5 {
    margin-top: 10px;
    font-size: 15px;
    color: #333333;
    word-break: break-all; /* Nouvelle propriété ajoutée */
  }

  .containerCard img {
    width: 200px;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
  }

  .containerTool {
    .containerRelative {
      width: 40%;
    }
    .imgInputIcon{
      background-color: transparent;
      top : 45%;
    }
  }
  .headercheckList {
    display: flex;
    margin-top: 50px;
    .containerIcon {
      background-color: #BDE3DC;
      height: 48px;
      width: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .containerContent {
      margin: 2px 0 0 13px;
      h5 {
        color: #1C1C1C;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Amiko', serif;
      }
      p {
        font-family: 'Mada', serif;
        font-size: 15px;
        color: #39302C;
      }
    }
  }

  .containercheckListContent {
    padding: 20px;
    border-radius: 16px;
    background-color: white;
    width: 100%;
    position: relative;

    .checkListContent {
      display: flex;
      flex-wrap: wrap;
      height: 60vh;
      background-color: white;
      border-radius: 16px;
      padding: 18px 18px 20vh 18px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
      overflow: auto;
      .active {
        border: 1px solid #22A18A;
      }
      .boxFlagrance {
        border: 1px solid #22A18A;
        display: flex;
        padding-left:20px;
        padding-right:20px;
        .right{
          height: 100%;
          display: flex;
          flex: 40%;
          justify-content: flex-end;
          align-items: center;
          .containerImg{
            background-color: #BDE3DC;
            height: 40px;
            width: 40px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor:pointer;

            img {
              height: 20px;
              width:22px;
              border:0;
            }
          }
          .sep{

            background: #22A18A;
            height: 60%;
            width: 1px;
            margin-left: 30px;
            margin-right: 30px;
          }
          .date {
            padding-left:10px;
            color: #39302C;
            font-size: 14px;
            font-family: Mada, serif;
            margin: 0 5%;
            .tag {
              color: #BDE3DC;
              background-color: #22A18A;
              border-radius: 50px;
              padding:4px 8px 4px 8px;
              letter-spacing: 3px;
            }
            .try {
              font-family: Mada, serif;
              background-color: #822E66;
              color: #ECE0E8;
            }
          .inProgress {
            font-family: Mada, serif;
            background-color : #F8FFA6;
            color: #22A18A;
          }
            .endTry{
              font-family: Mada, serif;
              background-color : #BDE3DC;
              color: #22A18A;
            }
          }
        }

        background-color: #F4FAF9;
        width: 100%;
        height: 144px;
        border-radius: 16px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
      }
      .left {
        flex: 0%;
        display: flex;
        align-items: center;
        /* Style par défaut de la case à cocher */
        .custom-checkbox input[type="checkbox"] {
          /* Masquer la case à cocher par défaut */
          display: none;
        }

        /* Style de l'apparence de la case à cocher personnalisée */
        .custom-checkbox label {
          display: inline-block;
          position: relative;
          padding-left: 25px; /* Espace pour afficher la coche */
          cursor: pointer;
          top: -14px;
        }

        /* Style de la boîte de la case à cocher */
        .custom-checkbox label:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          border: 2px solid #22A18A; /* Couleur de la bordure */
          border-radius: 4px;
        }

        /* Style de la coche (affichée lorsque la case est cochée) */
        .custom-checkbox input[type="checkbox"]:checked + label:before {
          background-color: #22A18A; /* Couleur de fond lorsque cochée */
        }

        /* Style de la coche (affichée en blanc) */
        .custom-checkbox label:after {
          content: '✓'; /* Code Unicode pour le V de validation */
          display: none;
          position: absolute;
          left: 4px;
          top: 3px;
          color: white; /* Couleur de la coche */
          font-size: 14px;
          line-height: 1;
          font-weight: bold;
        }

        /* Afficher la coche lorsque la case est cochée */
        .custom-checkbox input[type="checkbox"]:checked + label:after {
          display: block;
        }


        img {
          border: 2px solid #BDE3DC;
          border-radius: 8px;
          height:110px;
          width: 110px;
          object-fit: cover;
          margin-left: 6%;
        }
        .infoFlarance {
          position: relative;
          padding-left: 20px;
          width: 100%;
          white-space: break-spaces;

          input {
            border : 2px solid #BDE3DC;

            z-index: 0;
          }
          ul {
            position: absolute;
            width: calc(100% - 20px);
            height: 200px;
            z-index: 9;
            overflow:auto;
            li {
              width: 100%;
            }
          }
          span {
            height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          h5 {
            color:#1C1C1C;
            font-size: 25px;
            font-family: Amiko, serif;
            white-space: break-spaces;

            @media screen and (max-width: 1080px) {
              font-size: 24px;
            }

            @media screen and (max-width: 720px) {
              font-size: 13px;
            }

            @media screen and (max-width: 480px) {
              font-size: 12px;
            }

          }
          p{
            color : #22A18A;
            font-family: Mada, serif;
            font-size: 14px;
          }
        }

      }


      /* Barre de défilement personnalisée */
      &::-webkit-scrollbar {
        width: 4px;
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #B9B8B8;
        border-radius: 2px;
      }
    }
  }
  .containerButtonAddFlagrance {
    position: absolute;
    z-index: 1;
    background: linear-gradient(to bottom, transparent -85%, rgb(248,250,249) 100%);
    bottom: 0;
    border-radius: 15px;
    width: calc(100% - 30px);
    .bg {

      background: white;
    }
    .buttonAddFlagrance {
      background-color: white;
      border-radius: 15px;
      height: 10vh;
      border: 2px solid #22A18A;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        cursor: pointer;
        color:#22A18A;
        font-size: 23px;
        font-weight: bold;
        font-family: Amiko, serif;
        margin:0;
        img {
          background-color: #BDE3DC;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .previewFragranceEssais {
      display:none !important;
    }
    .containerContent{
      font-size: 12px;
    }
    .buttonAddFlagrance{
      p {

        font-size: 13px !important;
      }
    }
    .boxFlagrance {
      flex-direction: column;
      height: fit-content !important;
      min-height: 85px;
      padding: 10px !important;
      p {
        margin-bottom: 0;
      }
      .containerImg {
        margin-bottom: 6px;
      }
      .left {
        flex: 100% !important;
        order: 1;
        img{
          min-width: 70px !important;
          height: 70px !important;
        }
      }
      .right {
        flex: 100% !important;
        margin-top: 20px;
        order: 2;
        height: 0 !important;
      }
    }
  }
}
